
import PageHeader from './PageHeader';
import './css/analysispage.css';
import './css/blog.css';
import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "../config/index";
import BlogMainImg from "./images/blogs/Bitcoin-2.png";
import BlogContentImg1 from "./images/blogs/blogImage04-1024x768.jpg";
import BlogContentImg2 from "./images/blogs/blogImage05-1024x768.jpg";
import BlogContentImg3 from "./images/blogs/blogImage06.jpg";
import BlogContentImg4 from "./images/ana/img_2_0.webp";
import BlogContentImg5 from "./images/ana/img_1_3.webp";
import BlogContentImg6 from "./images/ana/img_2_0.webp";
import BlogContentImg7 from "./images/ana/img_3_1.webp";
import BlogContentImg8 from "./images/ana/img_4_2.webp";

import PDFImg from './images/pdf.png';

function Blogs() {
    const [AllSubCategoriesContent, setAllSubCategoriesContent] = useState([]);

    const [AllBooks, setAllBooks] = useState([]);
    const [AllVideos, setAllVideos] = useState([]);

    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [selectedCategoryName, setSelectedCategoryName] = useState(""); // Set your default subcategory name here
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const firstButtonRef = useRef();

    const GetAllBooks = () => {
    axios
        .post("AllItems", {
        table: "edu_books",
        })
        .then((res) => {
        if (res.data) {
            setAllBooks(res.data);
        } else {
            console.log("Error happened");
        }
        })
        .catch((err) => console.log(err));
    };


    const GetAllVideos = () => {
        axios
            .post("AllItems", {
            table: "edu_videos",
            })
            .then((res) => {
            if (res.data) {
                setAllVideos(res.data);
            } else {
                console.log("Error happened");
            }
            })
            .catch((err) => console.log(err));
        };


    const GetAllSubCategoriesContent = () => {
    axios
        .post("AllItems", {
        table: "sub_categories",
        })
        .then((res) => {
        if (res.data) {
            // Filter sub-categories with the desired category_name
            const filteredSubCategories = res.data.filter(
            (subcat) => subcat.category_name === "التعليم"
            );
            setAllSubCategoriesContent(filteredSubCategories);
        } else {
            console.log("Error happened");
        }
        })
        .catch((err) => console.log(err));
    };

    const handleButtonClick = (index) => {

        setActiveButtonIndex(index);
        const selectedSubcategory = AllSubCategoriesContent[index];
        setSelectedCategoryName(selectedSubcategory.name);

        // Filter blogs based on the selected subcategory's ID
        if(selectedSubcategory.name == "كتب تعليمية"){
            const filteredBlogs = AllBooks;
            setFilteredBlogs(filteredBlogs);
        }else if(selectedSubcategory.name == "كورسات تعليمية"){

            const filteredBlogs = AllVideos;
            setFilteredBlogs(filteredBlogs);
        }
    };

    useEffect(() => {
        GetAllSubCategoriesContent();
        
        GetAllBooks();
        GetAllVideos();

    }, []);

    useEffect(() => {
    // Auto-click the first button when the component mounts
    if (firstButtonRef.current) {
        firstButtonRef.current.click();
    }
    }, [firstButtonRef, AllBooks,AllVideos, AllSubCategoriesContent]);

    // Function to format the date
    function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
    }

    return (
    <>
        {/* Update the PageHeader with the selected category name */}
        <PageHeader
        className="all"
        PageHeaderImg={BlogContentImg2}
        category="التعليم"
        text={selectedCategoryName}
        />

        <section className="blog">
        <div className="container-fluid">
            <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="navigator-page">
                {AllSubCategoriesContent.length ? (
                    AllSubCategoriesContent.map((subcat, index) => (
                    <button
                        key={index}
                        ref={index === 0 ? firstButtonRef : null}
                        className={index === activeButtonIndex ? "active" : ""}
                        onClick={() => handleButtonClick(index)}
                    >
                        {subcat.name}
                    </button>
                    ))
                ) : (
                    <div>Loading...</div>
                )}
                </div>
            </div>

            <div className="analysis-page-content">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                {filteredBlogs.map((blog) => {
    const videoId = blog.youtube_video_link ? new URL(blog.youtube_video_link).searchParams.get("v") : null;
        console.log(blog.youtube_video_link);
    return (
        <div key={blog.id} className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="blog-content">
                    <div className="img">
                        {selectedCategoryName === "كتب تعليمية" ? (
                            <>
                                <img src={PDFImg} alt="كتب تعليمية" />
                            </>
                        ) : (
                            <>
                                {videoId ? (
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${videoId}`}
                                        title="YouTube video"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    // Handle the case when there's no YouTube link.
                                    <p>No YouTube link available</p>
                                )}
                            </>
                        )}
                        <span>{selectedCategoryName}</span>
                    </div>
                    <h1>{blog.name}</h1>
                    {selectedCategoryName === "كتب تعليمية" ? (
                        <>
                            <a href={blog.download_link}><i className="las la-download"></i>  تحميل الكتاب</a>
                        </>
                    ) : (
                        <>
                            {blog.youtube_video_link ? (
                                <a href={blog.youtube_video_link}>الفيديو على اليوتيوب</a>
                            ) : (
                                // Handle the case when there's no YouTube link.
                                <p>No YouTube link available</p>
                            )}
                        </>
                    )}
                </div>
        </div>
    );
})}


                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
    </>
    );
}

export default Blogs;