import './css/videosbenefits.css';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';

function VideosBenefits(){
    return(
        <>
            <section className='videos-benefits'>
                <div className='overlay'></div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='video-play'>
                                <h3> لفيديوهات التعليمية </h3>
                                <h1> مشاهدة الفيديوهات التعليمية </h1>

                                <RouterLink to="/education">
                                    <i class="las la-play"></i>
                                </RouterLink>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>

                            <div className='videos-benefits-text'>
                                <h1> نحرص  دائمًا على تقديم فوائد كبيرة لعملائنا </h1>

                                <ul>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        يمكنك الوصول إلى مكتبة الفيديوهات على منصتنا في أي وقت تشاء
                                    </li>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        نقدم  مجموعة متنوعة من الفيديوهات التعليمية تغطي مختلف جوانب التداول والاستثمار
                                    </li>
                                    <li>
                                        <i class="las la-check-circle"></i>
                                        نحرص على تحديث محتوى الفيديوهات بانتظام لضمان أن تحصل دائمًا على أحدث المعلومات والأدوات.
                                    </li>
                                </ul>

                                <div>
                                <RouterLink to="/education">
                                        الفيديوهات <i class="las la-angle-right"></i>
                                    </RouterLink>

                                    <RouterLink to="/blogs"> 
                                        المقالات <i class="las la-angle-right"></i>
                                    </RouterLink>


                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default VideosBenefits;