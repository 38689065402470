import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles



function EditBlog() {



    useEffect(() => {
        axios.get('session-admin')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session-admin is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [Blog, setBlog] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [SubCategories, setSubCategories] = useState([]);

    const [formInputs, setFormInputs] = useState({
        header: "",
        para: "",
        img: null,
        category_id: "",
        search_words: []

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (Blog.id) {
        setFormInputs({
            header: Blog.header,
            para: Blog.para,
            img: null,
            category_id: Blog.category_id,
            search_words: Blog.search_words,
        });
        }
    }, [Blog]);

    const handleChange = (name, value) => {
        handleInputChange();
    
        setFormInputs((prevFormInputs) => ({
        ...prevFormInputs,
        [name]: value,
        }));

        console.log(name,value)
    };
    


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { header, para, img, category_id,search_words } = formInputs;
        
    
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("header", header);
            form.append("para", para);
            form.append("img", img);
            form.append("category_id", category_id);
            form.append("search_words", search_words);

            const response = await axios.put("/edit-blog", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setBlog((prevBlog) => ({
                ...prevBlog,
                header,
                para,
                category_id,
                search_words,
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            }
        };




    axios.defaults.withCredentials = true;
    
    const getBlog = (itemId) => {
        let table = "blog";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setBlog(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    
    
    const GetSubCategories = ()=>{
        axios.post('AllItems', {
            table: 'sub_categories',
        })
        .then((res) => {
            if (res.data) {
                setSubCategories(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin = () => {
        axios.get('session-admin')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getBlog(id);
                }
            })
            .catch((err) => console.log(err));
    };
    


    useEffect(() => {
        getIfLogin();
        GetSubCategories();
        getBlog(id);
    
    }, [id]);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> الصورة  </label>
                                <input  onChange={(e) => handleChange('img', e.target.files[0])} type='file' name="img" accept="image/*"/>
                            </div>

                            <div className='input'>
                                <label> العنوان  </label>
                                <input value={formInputs.header}   onChange={(e) => handleChange('header', e.target.value)} type='text' required="required"  maxLength="250" name='header'/>
                            </div>

                            
                            <div className='input'>
                                <label>  المحتوى الكتابي </label>
                                <ReactQuill
                                    value={formInputs.para}
                                    name="para"
                                    onChange={(value) => handleChange('para', value)}
                                    modules={{
                                        toolbar: [
                                        [{ 'header': [1, 2, false] }],
                                        ['bold', 'italic', 'underline', 'strike'],
                                        ['link'],
                                        [{'color': []}, {'background': []}],
                                        [{'list': 'ordered'}, {'list': 'bullet'}],
                                        ['blockquote', 'code-block'],
                                        ],
                                    }}
                                    formats={[
                                        'header', 'bold', 'italic', 'underline', 'strike',
                                        'link', 'color', 'background',
                                        'list', 'bullet', 'blockquote', 'code-block'
                                    ]}
                                    />
                            </div>


                        <div className='input'>
                            <label>القسم</label>
                            <select
                                value={formInputs.category_id} // Set the selected value here
                                onChange={(e) => handleChange('category_id', e.target.value)}
                                name="category_id"
                                required
                            >
                                <option value="" disabled>Select a category</option>
                                {SubCategories.length ? (
                                    SubCategories.map((cat) => (
                                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                                    ))
                                ) : (
                                    <option disabled>Loading...</option>
                                )}
                            </select>
                        </div>




                            <div className='input'>
                                <label> الكلمات البحثية  </label>
                                <input placeholder='أدخل الكلمات بينهم فاصالة : مقال , تحليل ' value={formInputs.search_words}   onChange={(e) => handleChange('search_words', e.target.value)}  type='text' required="required"  maxLength="600" name='search_words'/>
                            </div>




                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditBlog;
