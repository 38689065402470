import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from './ShowButton';






function EditAdmin(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);

    


    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [User, setUser] = useState("");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [type, setType] = useState("admin");


    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };



    

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        uploadImage(selectedImage);
    };
    
    const uploadImage = (file) => {
        const formData = new FormData();
        
        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");


        formData.append("image", file);
        formData.append("userId", id);

    
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        
        axios.post("upload-image", formData, config)
            .then((response) => {

                Loading.classList.remove("active");

                let alertS = document.querySelector(".alert-success"); 
                alertS.classList.add("active");
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);

                return response.data;
                
            })
            .catch((error) => {
                Loading.classList.remove("active");


                let alertD = document.querySelector(".alert-danger"); 
                alertD.classList.add("active");
                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);

                console.error("Error uploading image: ", error);
                return null;
            });


    };
    



    const handleSubmit = (event) => {
        event.preventDefault();

        
        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");


        const data = {
            username: username || "",
            password: password || "",
            type: type || "",
        };
        
        
        
        if (id) {
            data.userId = id;
        }


        axios.put("/edit-admin", data)
        .then((response) => {
        Loading.classList.remove("active");
    
        let alertS = document.querySelector(".alert-success"); 
        alertS.classList.add("active");
        setTimeout(() => {
            alertS.classList.remove("active");
        }, 3000);
    
        console.log(response.data);
        })
        .catch((error) => {
        Loading.classList.remove("active");
    
        let alertD = document.querySelector(".alert-danger"); 
        alertD.classList.add("active");
        setTimeout(() => {
            alertD.classList.remove("active");
        }, 3000);
    
        console.log(error);
        });
    
        


    };





    const [show, setShow] = useState(false);

    const handleToggle = () => {
        setShow(!show);
    };

    const handleChange = (event) => {
        setPassword(event.target.value);
    };

    axios.defaults.withCredentials = true;


    const getUser = (userId) => {
        axios
        .post('admin', { userId })
        .then((res) => {
            if (res.data.message) {
                console.log(res.data.message);
            } else {
                setUser(res.data);

            }
        })
        .catch((err) => console.log(err));
    
    };

    const getIfLogin= ()=>{
        
    axios
    .get('session-admin')
    .then((res) => {
        if (!res.data.valid || id <= 0 || id== null || id== "") {
            navigate('/admin-login');

        }else{
            getUser(id);

        }
    })
    .catch((err) => console.log(err));

    }




    

    useEffect(() => {
        getIfLogin();
    }, []);
    




    

    return(
        <>

            <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>


            <section className='dashboard'>



                <div className='edit-form'>


                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> تعديل المستخدم :  {User.name}</h1>

                    <form onSubmit={handleSubmit}>

                    <div className='input'>
                        <label> الصورة  </label>
                        <input onChange={handleImageChange} type='file' name="image" accept="image/*"/>
                    </div>


                    <div className='input'>
                        <label> الاسم  </label>
                        <input  onChange={(event) => {setUsername(event.target.value);handleInputChange(event);}} type='text' required="required" defaultValue={User.username} maxLength="100" name='username'/>
                    </div>


                    <div className='input'>
                        <label> كلمة المرور  </label>
                        <input onChange={(e) => {handleChange(e);setPassword(e.target.value);handleInputChange(e);}} type={show ? 'text' : 'password'} placeholder="New Password" maxLength="100" name='password'/>
                        <ShowButton className="show-btn" show={show} handleToggle={handleToggle} />
                    </div>
                
                    <button type='submit'> حفظ </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default EditAdmin;