import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllSubCategories(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllSubCategoriesContent, setAllSubCategoriesContent] = useState([]);

    axios.defaults.withCredentials = true;





    const GetAllSubCategoriesContent = ()=>{
        axios.post('AllItems', {
            table: 'sub_categories',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllSubCategoriesContent(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session-admin')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'sub_categories',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                GetAllSubCategoriesContent();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };


useEffect(() => {

    GetAllSubCategoriesContent();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-sub-category'> + أضف </Link>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th> اسم القسم </th>
                            <th> المجال </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllSubCategoriesContent.length ? (

AllSubCategoriesContent.map(subcat => {

                        return (
                            <tr key={subcat.id}>
                            <td><h6>{subcat.id}</h6></td>

                            <td><h6>{subcat.name}</h6></td>
                            <td><h6>{subcat.category_name}</h6></td>


                            <td>
                                <Link className='edit' to={`/edit-sub-category?id=${subcat.id}`}> <i class="las la-edit"></i> </Link>
                            </td>
                            <td>
                                <button className='delete' onClick={() => deleteItem(subcat.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>
                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllSubCategories; 