import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate } from 'react-router-dom';
import ShowButton from './ShowButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

function AddBlog() {
    const navigate = useNavigate();

    const [header, setHeader] = useState('');
    const [para, setPara] = useState('');
    const [category_id, setCategoryId] = useState("");    
    const [img, setImg] = useState(null);    
    const [search_words, setSearchWords] = useState(null);    
    const [SubCategories, setSubCategories] = useState([]);


    
    const clearForm = () => {
        const formInputs = document.querySelectorAll('input[type="text"],textarea, input[type="password"],input[type="email"], input[type="file"]');
        formInputs.forEach((input) => {
            input.value = "";
        });
    };
    
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Make sure the file input is a File object
        // Ensure all necessary data is available
        if (!header || !para || !img || !category_id || !search_words) {
            console.log(category_id)
            console.error('Missing required data');
            return;
        }
    
        // Create a FormData object to send the data including the image file
        const formData = new FormData();
        formData.append('header', header);
        formData.append('para', para);
        formData.append('img', img);
        formData.append('category_id', category_id);
        formData.append('search_words', search_words);

        axios
            .post('/add-blog', formData, config)
            .then((response) => {
                // Handle the success response here
                console.log('Item added or updated successfully', response.data);
            })
            .catch((error) => {
                // Handle any errors here
                console.error('Error adding or updating user', error);
            });


            clearForm();
            setHeader("");
            setPara("");
            setCategoryId("");
            setSearchWords("");

    };
    

    
    
    const GetSubCategories = ()=>{
        axios.post('AllItems', {
            table: 'sub_categories',
        })
        .then((res) => {
            if (res.data) {
                setSubCategories(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };





    const [show, setShow] = useState(false);

    const handleToggle = () => {
        setShow(!show);
    };

    const getIfLogin = async () => {
        try {
            const res = await axios.get('session-admin');
            if (!res.data.valid) {
                navigate('/admin-login');
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getIfLogin();
        GetSubCategories();
    }, []);

    return (
        <div>
            {/* Your loading screen here */}
            <section className='dashboard'>
                <div className='edit-form'>
                    {/* Your success and error alert messages here */}
                    <form onSubmit={handleSubmit}>


                        <div className='input'>
                            <label> الصورة </label>
                            <input required onChange={(event) => setImg(event.target.files[0])} type='file' name="img" accept="image/*"/>

                        </div>

                        <div className='input'>
                            <label> العنوان </label>
                            <input
                                onChange={(event) => setHeader(event.target.value)}
                                type='text'
                                required
                                maxLength="250"
                                name='header'
                            />
                        </div>


                        
                        <div className='input'>
                            <label>  المحتوى الكتابي </label>
                            <ReactQuill
                            value={para}
                            onChange={(value) => setPara(value)}
                            name="para"
                            modules={{
                                toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                ['link'],
                                [{'color': []}, {'background': []}],
                                [{'list': 'ordered'}, {'list': 'bullet'}],
                                ['blockquote', 'code-block'],
                                ],
                            }}
                            formats={[
                                'header', 'bold', 'italic', 'underline', 'strike',
                                'link', 'color', 'background',
                                'list', 'bullet', 'blockquote', 'code-block'
                            ]}
                            />
                        </div>


                        
                        <div className='input'>
                            <label>القسم</label>
                            <select
                                onChange={(event) => setCategoryId(event.target.value)}
                                name="category_id"
                                required
                            >
                                <option  value=""> إختر القسم </option>

                                {SubCategories.length ? (

                                SubCategories.map(cat => {
                                return (
                                    <option value={cat.id}>{cat.name}</option>
                                );
                                })): 

                                (
                                        <td colSpan={5}>تحميل...</td>
                                    )}
                            </select>
                        </div>


                        

                        <div className='input'>
                                <label> الكلمات البحثية  </label>
                                <input placeholder='أدخل الكلمات بينهم فاصالة : مقال , تحليل '  onChange={(event) => setSearchWords(event.target.value)} type='text' required="required"  maxLength="600" name='search_words'/>
                            </div>




                        <button type='submit'> حفظ </button>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default AddBlog;
