import './css/pageheader.css';

function PageHeader(props){
    return(
        <>
            <section className='page-header'>
                <img src={props.PageHeaderImg}  alt="page header img" />
                <div className='overlay'></div>
                <div className='contianer'>
                    <div className='page-header-content'>
                        <span> {props.category} </span>
                        <h1 className={`${props.className}`}> {props.text} </h1>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageHeader;