import './css/footer.css';
import Logo from './images/logo.png';
import React, { useEffect ,useState} from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import { Link as RouterLink ,useNavigate} from 'react-router-dom';
import axios from '../config/index';

import BigLine from './images/BG_lines.png';

function Footer(props){


    const navigate = useNavigate();    

    const [Session, setSession] = useState(false);
    const [UserId, setUserId] = useState('');



    useEffect(() => {
        axios
    .get('/session-user')
    .then((res) => {
        if (res.data.valid) {
            setSession(true);
            setUserId(res.data.id);
            console.log("jkdf")
        }
    })
    .catch((err) => console.log(err));
    },[]);

    
    const logout = () => {
        axios
        .get('/logout')
        .then((res) => {
            if (res.data.logout) {
                window.location.href = '/login';
    
            } else {
                window.location.href = '/login';
            }
        })
        .catch((err) => console.log(err));
    };





    const location = useLocation(); // Get the current location from react-router

    useEffect(() => {
        // Scroll to the top of the page whenever the route changes
        window.scrollTo(0, 0);
        }, [location.pathname]); // Re-run this effect when the pathname changes
    
    
    return(
        <>
        
            <footer className={props.className}>
                <img src={BigLine} alt="bigline" />
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-2 col-md-2 col-sm-12 col-6'>
                            <div className='footer-content'>
                                <h1> الروابط مختصرة </h1>
                                <ul>
                                <li>
                                    <RouterLink to="/">
                                        الرئيسية
                                    </RouterLink>
                                </li>

                                <li>
                                <RouterLink to="/analysis">
                                        التحليلات
                                        <span></span>
                                    </RouterLink>
                                </li>



                                
                                <li>
                                <RouterLink to="/education">
                                        تعليم
                                        <span></span>
                                    </RouterLink>

                                
                                </li>

                                <li>
                                    <RouterLink to="/blogs">
                                        المقالات
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/trading-strategy">
                                        استراتجيات التداول
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/trading-companies">
                                        افضل شركات التداول
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/about-us">
                                        من نحن 
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        اتصل بنا
                                    </RouterLink>
                                </li>


                                </ul>
                            </div>
                        </div>



                        <div className='col-lg-2 col-md-2 col-sm-12 col-6'>
                            <div className='footer-content'>
                                <h1> التحليلات </h1>
                                <ul>
                                    <li>
                                        <a href='#'>
                                            العملات
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'>
                                            السلع
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'>
                                            المؤشرات
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'>
                                            الاسهم
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>



                        

                        <div className='col-lg-2 col-md-2 col-sm-12 col-6'>
                            <div className='footer-content'>

                                <h1> تعليم </h1>
                                <ul>
                                    <li>
                                        <a href='#'>
                                            كورسات تعليمية
                                        </a>
                                    </li>

                                    <li>
                                        <a href='#'>
                                            كتب تعليمية
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>


                        

                        <div className='col-lg-2 col-md-2 col-sm-12 col-6'>
                            <div className='footer-content'>

                                <h1> الحساب </h1>
                                <ul>
                                        {Session == true? 
                                        <>
                                            <li>
                                                <RouterLink  onClick={logout}>
                                                    تسجيل الخروج
                                                </RouterLink>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    شروط الإستخدام
                                                </a>
                                            </li>
                                        </>:<>
                                        
                                        <li>
                                            <RouterLink to="/login">
                                                تسجيل الدخول
                                            </RouterLink>
                                        </li>
                                        <li>
                                            <RouterLink to="/sign-up">
                                                حساب جديد
                                            </RouterLink>
                                        </li>
                                        <li>
                                            <a href="#">
                                                شروط الإستخدام
                                            </a>
                                        </li>

                                        </>}
                                        
                                    </ul>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img src={Logo} alt="logo " />
                                </div>

                                <a href="tel:info@cmtpfx.com "> info@cmtpfx.com <i class="las la-envelope"></i></a>
                                <a href="tel:+9647757897595"> +964 775 789 7595 <i class="las la-phone-volume"></i></a>

                                <h4> تابعنا على وسائل التواصل الاجتماعي </h4>
                                <div className='socials'>
                                    <a href="https://www.youtube.com/@CMTPFX">
                                        <i class="lab la-youtube"></i>
                                    </a>
                                    <a href="https://web.facebook.com/profile.php?id=61552025681668">
                                    <i class="lab la-facebook-f"></i>
                                    </a>

                                    <a href="https://t.me/Cmtpfx">
                                        <i class="lab la-telegram"></i>
                                    </a>


                                    <a href="https://www.instagram.com/cmtpfx/">
                                        <i class="lab la-instagram"></i>
                                    </a>
                                    <a href="https://twitter.com/cmtpfx32182">
                                        <i class="lab la-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'> 
                            <div className='copy-right'>
                                <h6> &copy; CMTPFX , All rights reserved , Product By <a href="https://abdulrhmanelsawy.github.io/abdelrhman-elsawy/">Abdelrhman Elsawy</a> </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}


export default Footer;