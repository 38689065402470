import './css/numbers.css';

import BigLine from './images/BG_lines.png';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';

function Number(){
    return(
        <>  
            <section className='numbers'>
                <img src={BigLine} alt="big line" />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='header'>
                                <h1>  إليك بعض الإحصائيات والأرقام الهامة حول منصتنا وخدماتنا </h1>
                                <p> نحن نقدم لك في شركة CMTPFX مجموعة من الإحصائيات والأرقام التي تبرز نجاحنا وتعزز ثقتك بخدماتنا. </p>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='number'>
                                <h3> 6,515 </h3>
                                <h4> كورسات تعليمية </h4>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='number'>
                                <h3> 2,155</h3>
                                <h4> كتب تعليمية </h4>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='number'>
                                <h3> 8,181</h3>
                                <h4> تحليل </h4>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='number'>
                                <RouterLink to="/sign-up"> سجل حسابك معنا </RouterLink>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Number;