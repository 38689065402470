import './css/login.css';
import { Link, useNavigate,useParams } from 'react-router-dom';
import LoginImg from './images/landingback.webp';
import React, { useState,useEffect } from 'react';
import ShowButton from './ShowButton';
import axios from '../config/index';




function EmailConfirmation() {

        const [activationcode, setactivationcode] = useState("");
        const [ActivateStatus, setActivateStatus] = useState("");

        
        const navigate = useNavigate();    

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        if(email == "" || email == null || email == undefined){
            navigate('/sign-up')
        }    
        axios.defaults.withCredentials = true;





    
        const emailconfirm = (e) => {
            e.preventDefault();
            axios.post("emailactivation", {
            email: email,
            code:activationcode

            })
            .then((response) => {
                if (response.data.message == "Email activated successfully") {
                    setActivateStatus(response.data.message);

                    navigate('/login')

                } else {
                    setActivateStatus(response.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        };


    



    
return (
    <>

        <section className='login'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <div className='login-content'>
                <h1> تم ارسال كود تفعيلك على البريد الخاص بك </h1>

                
                {ActivateStatus ? (
                    <h3 className='err-msg'> {ActivateStatus} </h3>
                    ) : null}

                <form>

                <div className='input'>
                    <label> كود التفعيل  </label>
                    <input
                    type='text'
                    name='activationcode'
                    required
                    onChange={(e) => {
                        setactivationcode(e.target.value);}}
                    />
                </div>


                    <button onClick={emailconfirm}  type='submit'>
                        إرسال 
                    </button>

                <Link to='/login'> هل لديك حساب يالفعل ؟ </Link>
                </form>
            </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <div className='login-content'>
                <img src={LoginImg} alt='login' />
            </div>
            </div>

            
        </div>
        
        </div>
    </section>

    </>
);
}

export default EmailConfirmation;
