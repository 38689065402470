import './css/navbar.css';
import React, { useState } from 'react';

import Logo from './images/logo.png';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';
import { useEffect,useRef  } from "react";
import $ from 'jquery';
import axios from '../config/index';



function Navbar(props){
    const navigate = useNavigate();    

    const [Session, setSession] = useState(false);
    const [UserId, setUserId] = useState('');



    useEffect(() => {
        axios
    .get('/session-user')
    .then((res) => {
        if (res.data.valid) {
            setSession(true);
            setUserId(res.data.id);
            console.log("jkdf")
        }
    })
    .catch((err) => console.log(err));
    },[]);

    
    const logout = () => {
        axios
        .get('/logout')
        .then((res) => {
            if (res.data.logout) {
                window.location.href = '/login';
    
            } else {
                window.location.href = '/login';
            }
        })
        .catch((err) => console.log(err));
    };





    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const handleSubMenuClick = (index) => {
    if (activeSubMenu === index) {
        setActiveSubMenu(null); // Close the sub-menu if it's already open
    } else {
        setActiveSubMenu(index); // Open the sub-menu
    }
    };


    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $("nav").addClass("active");

            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    }, []);
    


    return(
        <>
            <nav className={props.className}>

                <div className='container-fluid'>
                    <div className='nav-content'>
                        <div className='logo'>
                            <img src={Logo} alt="cmtpfx logo" />
                        </div>

                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-elements">
                        <button id="close-nav"> <i className="las la-times"></i> </button>
                        
                            <ul>
                                <li>
                                    <RouterLink to="/">
                                        الرئيسية
                                    </RouterLink>
                                </li>

                                <li>
                                <RouterLink to="/analysis">
                                        التحليلات
                                        <span></span>
                                    </RouterLink>
                                </li>



                                
                                <li>
                                <RouterLink to="/education">
                                        تعليم
                                        <span></span>
                                    </RouterLink>

                                
                                </li>

                                <li>
                                    <RouterLink to="/blogs">
                                        المقالات
                                    </RouterLink>
                                </li>


                                <li>
                                    <RouterLink to="/trading-strategy">
                                        استراتجيات التداول
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/trading-companies">
                                        افضل شركات التداول
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/about-us">
                                        من نحن 
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        اتصل بنا
                                    </RouterLink>
                                </li>

                                <li>
                                <a href="#" onClick={() => handleSubMenuClick(3)}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </a>

                                    <ul className={`sub-elements ${activeSubMenu === 3 ? 'active' : ''}`}>
                                        {Session == true? 
                                        <>
                                            <li>
                                                <RouterLink  onClick={logout}>
                                                    تسجيل الخروج
                                                </RouterLink>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    شروط الإستخدام
                                                </a>
                                            </li>
                                        </>:<>
                                        
                                        <li>
                                            <RouterLink to="/login">
                                                تسجيل الدخول
                                            </RouterLink>
                                        </li>
                                        <li>
                                            <RouterLink to="/sign-up">
                                                حساب جديد
                                            </RouterLink>
                                        </li>
                                        <li>
                                            <a href="#">
                                                شروط الإستخدام
                                            </a>
                                        </li>

                                        </>}
                                        
                                    </ul>

                                </li>

                            </ul>
                        </div>

                        
                        <div className='langs'>
                            <RouterLink to="/sign-up">
                                <span></span>
                                سجل الان
                            </RouterLink>
                        </div>
                    </div>
                </div>
        
            </nav>
        </>
    )
}

export default Navbar;