import './css/dashboard.css';
import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function Dashboard(){


    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const [usersCount, setUsersCount] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const [subCategoriesCount, setSubCategoriesCount] = useState(0);


    const [users, setUsers] = useState([]);

    


    
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
                navigate('/dashboard');
            }else{
                // navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    const getCount = (tableName, setCount) => {
        axios.post('get-item-count', {
            table: tableName,
        })
        .then((res) => {
            if (res.data) {
                setCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    
    const getUserCount = () => {
        getCount('users', setUsersCount);
    };


    const getCommentCount = () => {
        getCount('comments', setCommentsCount);
    };
    
    
    const getSubCategoriesCount = () => {
        getCount('sub_categories', setSubCategoriesCount);
    };
    


    const GetAllUsers = ()=>{
        axios.post('AllItems', {
            table: 'users',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setUsers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session-admin')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }

    
useEffect(() => {
    getUserCount();
    getCommentCount();
    getSubCategoriesCount();
    GetAllUsers();

    getIfLogin();
}, []);




    

    return(
        <>
            <section className='dashboard'>
                <div className='stats-content'>
                    <Link to="/all-suppliers">
                        <div className='stat'>
                            <i className="las la-users"></i>
                            <div className='info'>
                                <h1>{usersCount}</h1>
                                <h2> المستخدين </h2>
                            </div>
                        </div>
                    </Link>
                    

                    <Link to="/all-products">
                        <div className='stat'>
                        <i className="las la-question"></i>
                            <div className='info'>
                                <h1>{commentsCount}</h1>
                                <h2> التعليقات </h2>
                            </div>
                        </div>
                    </Link>


                    <Link to="/all-posts">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{subCategoriesCount}</h1>
                                <h2> الأقسام </h2>
                            </div>
                        </div>
                    </Link>

                    
                </div>




                <div className='results-table'>

                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>الصورة</th>
                            <th> الاسم </th>
                            <th>خيارات</th>

                        </tr>
                    </thead>
                    <tbody>

                    {users && users.length > 0 ? (
                        users.map((user) => (
                            <tr key={user.id}>
                            <td><h6>{user.id}</h6></td>
                            <td><img src={`uploads//${user.img}`} alt="User Image"/></td>
                            <td><h2>{user.name}</h2></td>
                            <td><Link to={`/edit-user?id=${user.id}`}> تعديل </Link></td>
                            </tr>
                        ))
                        ) : (
                        <tr>
                            <td colSpan="4"> فارغ </td>
                        </tr>
                        )}

                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default Dashboard;