import './css/features.css';

import Coins from './images/coins.webp';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';

function Features(){
    return(
        <>
            <section className='features'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='features-img'>
                                <img src={Coins} alt="coins" />
                            </div>
                        </div>

                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='features-text'>
                                <h1> عندما تختار التعامل مع شركة CMTPFX </h1>
                                <p>  
                                ستتمتع بالعديد من المميزات التي تميزنا عن الشركات الأخرى    
                                </p>

                                <div className='text'>
                                    <div> 
                                        <h2>
                                        تحليلات متقدمة
                                        </h2>
                                        <p>
                                        نقدم أحدث التحليلات وأدوات تحليلية متقدمة لمساعدتك في فهم الأسواق واتخاذ قرارات مستنيرة. نحن نساعدك على تقييم الاتجاهات وتحديد الفرص الاستثمارية.
                                        </p>
                                    </div>

                                    <i class="las la-tasks"></i>
                                </div>

                                <div className='text'>
                                    <div> 
                                        <h2>
                                        تكنولوجيا متقدمة
                                        </h2>
                                        <p>
                                        نستخدم أحدث التقنيات والمنصات للتداول لضمان تجربة سلسة وفعالة
                                        </p>
                                    </div>

                                    <i class="las la-laptop-medical"></i>
                                </div>


                                <div className='text'>
                                    <div> 
                                        <h2>
                                        رؤية مشتركة
                                        </h2>
                                        <p>
                                        نحن نسعى دائمًا لتحقيق نجاحك المالي. نكون شركاء في رحلتك لتحقيق أهدافك وتحقيق التفوق في عالم التداول والاستثمار.
                                        </p>
                                    </div>

                                    <i class="las la-eye"></i>
                                </div>

                                <h3>
                                    عندما تختار  <span>CMTPFX، </span>  تختار الجودة والتميز في عالم التداول. انضم إلينا اليوم واستمتع بكل هذه المميزات وأكثر.
                                </h3>

                                <RouterLink to="/sign-up"> سجل حسابك </RouterLink>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Features;