import './css/services.css';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';

function Services(){
    return(
        <>
            <section className='services'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='header'>
                                <div>
                                    <h1> إليك بعض خدماتنا المميزة  </h1>
                                    <p>نقدم لك نظرة عامة على شركة CMTPFX والخدمات التي نقدمها. نحن ملتزمون بتزويد عملائنا بأحدث المعلومات والتقنيات في عالم التداول والاستثمار. </p>
                                </div>

                                <RouterLink to="/sign-up">
                                    إبدا الان
                                </RouterLink>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                            <i class="las la-chart-line"></i>
                                <h1> التحليلات </h1>

                                <p> تعرف على أحدث التحليلات للعملات، السلع، المؤشرات، والأسهم. نحن نوفر لك تحليلات دقيقة ومعلومات محدثة تساعدك في اتخاذ قرارات مستنيرة في التداول.  </p>

                                <RouterLink to='/analysis'> 
                                    <i class="las la-angle-right"></i>
                                    مشاهدة المحتوى
                                </RouterLink>
                            </div>
                        </div>


                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                            <i class="las la-school"></i>
                                <h1> التعليم </h1>

                                <p> اكتسب المزيد من المعرفة من خلال كورسات تعليمية مميزة وكتب تعليمية متخصصة. في CMTPFX، نؤمن بأهمية التعليم في تطوير مهارات التداول، ولذلك نقدم موارد تعليمية متنوعة تناسب جميع المستويات. </p>

                                <RouterLink to='/education'> 
                                    <i class="las la-angle-right"></i>
                                    مشاهدة المحتوى
                                </RouterLink>
                            </div>
                        </div>


                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                            <i class="las la-clipboard-list"></i>
                                <h1> استراتجيات التداول  </h1>

                                <p> استكشف أفضل الاستراتجيات لتحقيق نجاح مستدام في التداول. نحن نقدم إرشادات ونصائح حول كيفية تطوير استراتجيات فعالة وكسب أرباح ملموسة. </p>

                                <RouterLink to='/trading-strategy'> 
                                    <i class="las la-angle-right"></i>
                                    مشاهدة المحتوى
                                </RouterLink>
                            </div>
                        </div>

                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <i class="las la-tasks"></i>
                                <h1> افضل شركات التداول  </h1>

                                <p> نقدم قائمة بأفضل شركات التداول لمساعدتك في اختيار الوسيط المناسب. نحن نعرض معلومات حول الوسطاء الموثوقين والمواقع التي يمكنك الاعتماد عليها. </p>

                                <RouterLink to='/trading-companies'> 
                                    <i class="las la-angle-right"></i>
                                    مشاهدة المحتوى
                                </RouterLink>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;