import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditUser() {



    useEffect(() => {
        axios.get('session-admin')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session-admin is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [User, setUser] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        username: "",
        password: "",
        img: null,
        active: "",
        email: ""

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (User.id) {
        setFormInputs({
            username: User.username,
            password: User.password,
            img: null,
            email: User.email,
            active: User.active,
        });
        }
    }, [User]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { username, password, img, active,email } = formInputs;
        
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("username", username);
            form.append("password", password);
            form.append("img", img);
            form.append("active", active);
            form.append("email", email);

            const response = await axios.put("/edit-user", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setUser((prevUser) => ({
                ...prevUser,
                username,
                password,
                active,
                email,
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            }
        };




    axios.defaults.withCredentials = true;
    
    const getUsers = (itemId) => {
        let table = "users";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setUser(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session-admin')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getUsers(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getUsers(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> اسم المستخدم  </label>
                                <input value={formInputs.username}  onChange={handleChange} type='text' required="required"  maxLength="250" name='username'/>
                            </div>

                            
                            <div className='input'>
                                <label>  كلمةالمرور </label>
                                <input placeholder='أدخل كلمة المرور الجديدة'  onChange={handleChange} type='password'  maxLength="250" name='password'/>
                            </div>


                            
                            <div className='input'>
                                <label> البريد الإلكتروني  </label>
                                <input value={formInputs.email}  onChange={handleChange} type='email' required="required"  maxLength="250" name='email'/>
                            </div>


                            <div className='input'>
                                <label> الصورة  </label>
                                <input onChange={handleChange} type='file' name="img" accept="image/*"/>
                            </div>


                            <div className='input'>
                                <label> الحالة </label>
                                <select
                                    required
                                    onChange={handleChange}
                                    name='active'
                                >
                                    {User.active == 0? 
                                    <>
                                        <option selected value="0">غير مفعل</option>
                                        <option value="1">مفعل</option>
                                    </>:
                                    <>
                                    <option selected value="1">مفعل</option>
                                    <option  value="0">غير مفعل</option>
                                    </>}
                                </select>
                            </div>



                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditUser;
