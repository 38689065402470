import './css/blog.css';

import BlogMainImg from './images/blogs/Bitcoin-2.png';

import BlogContentImg1 from './images/blogs/blogImage04-1024x768.jpg';
import BlogContentImg2 from './images/blogs/blogImage05-1024x768.jpg';
import BlogContentImg3 from './images/blogs/blogImage06.jpg';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';


import React, { useState, useEffect } from 'react';
import axios from '../config/index';

function Blog(){

    const [blogs, setBlogs] = useState([]);
    const [subCategories, setSubCategories] = useState([]);


    
const getBlogs = () => {


    axios
    .post('AllItems', {
        table: 'blog',
    })
    .then((res) => {
        if (res.data) {
        setBlogs(res.data);
        } else {
        console.log('Error happened');
        }
    })
    .catch((err) => console.log(err));
};


const getSubCategories = () => {
    axios
    .post('AllItems', {
        table: 'sub_categories',
    })
    .then((res) => {
        if (res.data) {
        setSubCategories(res.data);
        } else {
        console.log('Error happened');
        }
    })
    .catch((err) => console.log(err));
};


function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
    }

const getCategoryName = (category_id) => {
    const subcategory = subCategories.find((sub) => sub.id === category_id);
    return subcategory ? subcategory.name : '';
};


useEffect(() => {
    getBlogs(); // Fetch all blogs
    getSubCategories(); // Fetch all sub-categories
}, []);

    return(
        <>
            <section className='blog'>
                <div className='container-fluid'>
                    <div className="row">

                        <div className='col-lg-9 col-md-9 col-sm-12 col-12'>

                            <div className="row">
                                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className="header">
                                        <h1> إليك أخر المقالات </h1>
                                    </div>
                                </div>


                                {blogs.slice(-3).map((blogItem, index) => {
                                    const subcategoryName = getCategoryName(blogItem.category_id);
                                    return (
                                        
                                        <div key={index} className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                        <div className="blog-content">
                                            <div className="img">
                                                <img src={`uploads/${blogItem.img}`} />
                                                <span> {subcategoryName} </span>
                                            </div>
    
                                            <h1> {blogItem.header}</h1>
                                            <h3>{formatDate(blogItem.date)}</h3>
                                            <h4> <span>  </span> <i class="las la-comment-alt"></i></h4>
                                        </div>
                                    </div>
                                    );
                                    })}

                                

                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className='blog-refer-container'>
                                <img src={BlogMainImg} />
                                <div className='overlay'></div>
                                <div className='text'>
                                    <h1> 
                                    مقالات تحليلية ونصائح عملية
                                    </h1>

                                    <p> 
                                    مقالاتنا هي مصدرك الموثوق لاستكشاف عالم التداول والاستثمار. نقدم لك مقالات مميزة تغطي مختلف الجوانب في عالم المال والأعمال
                                    </p>

                                    <RouterLink to="/blogs"> المقالات </RouterLink>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog;