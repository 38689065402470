import './css/benefits.css';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';

function Benefits(){
    return(
        <>
            <section className='benefits'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='header'>
                                <div>  
                                    <h1> إليك بعض الفوائد التي نقدمها </h1>
                                    <p> عندما تختار التعامل مع شركة CMTPFX، ستستفيد من العديد من الفوائد التي تضمن لك تجربة تداول واستثمار ممتازة</p>
                                </div>

                                <RouterLink to="/sign-up"> إبدء الان </RouterLink>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='benefit'>
                            <i class="las la-university"></i>

                                <h1>
                                    تعليم متخصص
                                </h1>

                                <p>
                                    حن نقدم مجموعة من الدروس والكورسات التعليمية المصممة خصيصًا لتزويدك بالمعرفة والمهارات الضرورية لتحقيق النجاح في عالم التداول. ستتعلم كيفية تحليل الأسواق واتخاذ القرارات الاستثمارية الصائبة
                                </p>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='benefit'>
                            <i class="las la-retweet"></i>

                                <h1>
                                    تحليلات محدثة   
                                </h1>

                                <p>
                                    سوف تحصل على الوصول إلى أحدث التحليلات والبيانات حول الأسواق المالية. يمكنك الاعتماد على تلك المعلومات لاتخاذ قرارات مستنيرة في التداول
                                </p>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='benefit'>
                            <i class="las la-copy"></i>

                                <h1>
                                مقالات متخصصة
                                </h1>

                                <p>
                                    نقدم مجموعة متنوعة من المقالات المتخصصة حول مواضيع مختلفة في عالم التداول والاستثمار. ستجد نصائح قيمة واستراتيجيات تساعدك في تحسين أدائك
                                </p>
                            </div>
                        </div>



                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='benefit'>
                                <i class="las la-building"></i>

                                <h1>
                                أفضل شركات التداول
                                </h1>

                                <p>
                                    نوفر لك معلومات حول أفضل شركات التداول والوسطاء الموثوقين. سنساعدك في اختيار الشريك المناسب لاحتياجاتك وأهدافك.
                                </p>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </section>
        </>
    )
}

export default Benefits;