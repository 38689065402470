import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate } from 'react-router-dom';

function AddSubCategory() {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [category_name, setCategoryName] = useState('');

    const clearForm = () => {
        setName('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Ensure all necessary data is available
        if (!name || !category_name) {
            console.error('Missing required data');
            return;
        }

        axios
            .post('/add-sub-category', { name, category_name })
            .then((response) => {
                // Handle the success response here
                clearForm();
            })
            .catch((error) => {
                // Handle any errors here
            });
    };

    const getIfLogin = async () => {
        try {
            const res = await axios.get('session-admin');
            if (!res.data.valid) {
                navigate('/admin-login');
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getIfLogin();
    }, []);

    return (
        <div>
            <section className='dashboard'>
                <div className='edit-form'>
                    <form onSubmit={handleSubmit}>
                        <div className='input'>
                            <label>اسم القسم</label>
                            <input
                                type='text'
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                required
                                maxLength="100"
                                name='name'
                            />
                        </div>

                        <div className='input'>
                            <label>المجال</label>
                            <select
                                value={category_name}
                                onChange={(e) => setCategoryName(e.target.value)}
                                name="category_name"
                                required
                            >
                                <option value="">إختر المجال</option>

                                <option value="التحليلات">التحليلات</option>
                                <option value="التعليم">التعليم</option>
                                <option value="المقالات">المقالات</option>
                                <option value="استراتجيات التداول">استراتجيات التداول</option>
                                <option value="افضل شركات التداول">افضل شركات التداول</option>
                            </select>
                        </div>

                        <button type='submit'>حفظ</button>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default AddSubCategory;
