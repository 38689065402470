import './css/landing.css';
import { Link as RouterLink ,useNavigate} from 'react-router-dom';

function Landing(){
    return(
        <>
            <section className='landing'>
                <div className='overlay'></div>
                <div className='container-fluid'>
                    <div className='landing-text'>
                        <h1> 
                            مرحبًا بك في عالم التداول والاستثمار مع شركة 
                            <span> cmtpfx </span>
                        </h1>

                        <div className='data'>
                            <div>
                                <h4> المميزات </h4>
                                <h2> سهل الإستخدام </h2>
                            </div>

                            <span>  </span>

                            <div> 
                                <h4> أحصل على</h4>
                                <h2> 5000 + مقالات وكتب تعليمية</h2>
                            </div>
                        </div>

                        <div className='links'>
                            <RouterLink to="/contact-us">
                                تواصل معنا
                            </RouterLink>

                            <RouterLink to="/sign-up">
                                سجل معنا <i class="las la-angle-right"></i>
                            </RouterLink>
                        </div>

                        <h3>  <span> للنجاح </span>  سواء كنت مبتدئًا في عالم التداول أو محترفًا ذو خبرة، فإننا هنا لنقدم لك المعرفة والأدوات اللازمة </h3>
                    </div>

                    <div className='call-us'>
                        <a href="tel:+9647757897595"> 
                            <i class="las la-phone-volume"></i>
                        </a>
                    </div>


                    <div className='down-benefits'>
                        <div className='main-benefit'>
                            <h1>
                                أحصل على كل المعلومات التي تريد الان
                            </h1>
                            <RouterLink to="/sign-up">
                                قم بعمل حساب على منصتنا
                            </RouterLink>
                        </div>

                        
                        <div className='benefit'>
                            <i class="las la-chart-line"></i>
                            <div>
                                <h3> التحليلات </h3>
                                <p>
                                تعرف على أحدث التحليلات للعملات، السلع، المؤشرات، والأسهم.</p>
                            </div>
                        </div>
                        
                        <div className='benefit'>
                            <i class="las la-school"></i>
                            <div>
                                <h3> التعليم </h3>
                                <p>
                                اكتسب المزيد من المعرفة من خلال كورسات تعليمية مميزة وكتب تعليمية متخصصة.</p>
                            </div>
                        </div>

                        <div className='benefit'>
                            <i class="las la-newspaper"></i>
                            <div>
                                <h3> المقالات </h3>
                                <p> استفد من مقالاتنا المتخصصة حول التداول والاستثمار </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Landing;