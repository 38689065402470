import './css/login.css';
import { Link, useNavigate } from 'react-router-dom';
import LoginImg from './images/landingback.webp';
import React, { useState,useEffect } from 'react';
import ShowButton from './ShowButton';
import axios from '../config/index';



function SignUp() {

    
        useEffect(() => {
            axios
        .get('/session-user')
        .then((res) => {
            if (res.data.valid) {
                navigate('/');
            } 
        })
        .catch((err) => console.log(err));
        },[]);




        const [username, setUserName] = useState("");
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");



        const [recipientEmail, setRecipientEmail] = useState('');
        const [subject, setSubject] = useState('');
        const [body, setBody] = useState('');
        const [message, setMessage] = useState('');


        const navigate = useNavigate();    
        const [signupStatus, setSignupStatus] = useState("");
    
        axios.defaults.withCredentials = true;


        
        function generateActivationCode() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let code = '';
            for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters.charAt(randomIndex);
            }
            return code;
        }
        


        const SendCode = (activationCodeparam) => {
            const activationCode = activationCodeparam // Generate a random activation code
            const subject = ('Email Activation');
            const body = (`Dear ${username},\n\nThank you for signing up. Your activation code is: ${activationCode}\n\nPlease use this code to activate your account.\n\nBest regards,\nThe Team`);
            axios.post('/send-email-activation', { email, subject, body })
            .then((res) => {
                setMessage(res.data.message);
                setRecipientEmail(email);
                setSubject('Email Activation');
                setBody(`Dear ${username},\n\nThank you for signing up. Your activation code is: ${activationCode}\n\nPlease use this code to activate your account.\n\nBest regards,\nThe Team`);
            })
            .catch((err) => {
                setMessage(err.response.data.message);
            });
        }


    
        const signup = (e) => {
            e.preventDefault();
            const activationcode = generateActivationCode();


            axios.post("signup", {
            username: username,
            email: email,
            password: password,
            code:activationcode

            })
            .then((response) => {
                if (response.data.message == "Visit your email for activation code.") {
                    SendCode(activationcode);
                setSignupStatus(response.data.message);
                navigate(`/email-confirmation?email=${email}`)
                } else {
                setSignupStatus(response.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        };
        



const [show, setShow] = useState(false);

const handleToggle = () => {
    setShow(!show);
};




    
return (
    <>

        <section className='login'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <div className='login-content'>
                <h1> سجل معنا الان </h1>

                {signupStatus ? (
                    <h3 className='err-msg'> {signupStatus} </h3>
                    ) : null}

                <form>

                <div className='input'>
                    <label> الاسم </label>
                    <input
                    type='text'
                    name='username'
                    required
                    onChange={(e) => {
                        setUserName(e.target.value);}}
                    />
                </div>


                <div className='input'>
                    <label>البريد الألكتروني</label>
                    <input
                    type='text'
                    name='email'
                    required
                    onChange={(e) => {
                        setEmail(e.target.value);}}
                    />
                </div>


                <div className='input'>
                    <label> كلمة السر</label>
                    <input
                    type={show ? 'text' : 'password'}
                    name='password'
                    required
                    onChange={(e) => {
                        setPassword(e.target.value);}}
                    />
                <ShowButton className="show-btn" show={show} handleToggle={handleToggle} />
                </div>


                    <button onClick={signup}  type='submit'>
                    تسجيل جديد
                    </button>

                <Link to='/login'> هل لديك حساب يالفعل ؟ </Link>
                </form>
            </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <div className='login-content'>
                <img src={LoginImg} alt='login' />
            </div>
            </div>

            
        </div>
        
        </div>
    </section>

    </>
);
}

export default SignUp;
