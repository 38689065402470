import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllBlogs(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [Blogs, setBlogs] = useState([]);
    const [SubCategories, setSubCategories] = useState([]);

    axios.defaults.withCredentials = true;





    const GetBlogs = ()=>{
        axios.post('AllItems', {
            table: 'blog',
        })
        .then((res) => {
            if (res.data) {
                setBlogs(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    
    const GetSubCategories = ()=>{
        axios.post('AllItems', {
            table: 'sub_categories',
        })
        .then((res) => {
            if (res.data) {
                setSubCategories(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session-admin')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'blog',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                GetBlogs();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };


    const getCategoryName = (category_id) => {
        const subcategory = SubCategories.find((sub) => sub.id === category_id);
        return subcategory ? subcategory.name : '';
    };

useEffect(() => {

    GetBlogs();
    getIfLogin();
    GetSubCategories();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-blog'> + أضف </Link>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>الصورة</th>
                            <th> الاسم </th>
                            <th> العنوان </th>
                            <th> عدد المشاهدات </th>

                            <th> القسم </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {Blogs.length ? (

                        Blogs.map(blog => {
                            const subcategoryName = getCategoryName(blog.category_id);

                        return (
                            <tr key={blog.id}>
                            <td><h6>{blog.id}</h6></td>

                            <td><img src={`/uploads/${blog.img}`} alt="User avatar"/></td>
                            <td><h6>{blog.header}</h6></td>
                            <td><h6>{subcategoryName}</h6></td>

                            <td><h6>{blog.views}</h6></td>

                            <td>
                                <Link className='edit' to={`/edit-blog?id=${blog.id}`}> <i class="las la-edit"></i> </Link>
                            </td>
                            <td>
                                <button className='delete' onClick={() => deleteItem(blog.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>


                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllBlogs;