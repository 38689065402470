import React, { useState } from 'react';
import './css/contact.css';
import ContactImg from './images/contact.webp';
import axios from '../config/index'; // Make sure this import is correct

function Contact() {

return (
    <section className="contact">
    <div className="container-fluid">
        <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="contact-images">
            <div className="back-lay"></div>
            <img loading="lazy" src={ContactImg} alt="ContactUs" />
            <div className="contact-numbers">

                <div className="number">
                <h2>10 +</h2>
                <h3> خدمات مميزة </h3>
                </div>
                <div className="number">
                <h2>12 +</h2>
                <h3>سنوات من الخبرة</h3>
                </div>
            </div>
            </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="contact-form">
            <h1>
                تواصل معنا <span>على</span> إبقى
            </h1>
            <p> نحن هنا لنساعدك ونستمع إلى استفساراتك وملاحظاتك. لا تتردد في الاتصال بنا عبر وسائل الاتصال التالية</p>


            <form>
                <div className="inputs">
                <div className="input">
                    <input
                    type="text"
                    name="name"
                    placeholder="ضع اسمك هنا *"
                    required
                    />
                </div>
                <div className="input">
                    <input
                    type="email"
                    name="email"
                    placeholder="البريد الإلكتروني *"
                    required
                    />
                </div>
                </div>
                <div className="inputs">
                <div className="input">
                    <input
                    type="text"
                    name="subject"
                    placeholder="عنوان الرسالة*"
                    required
                    />
                </div>
                <div className="input">
                    <input
                    type="tel"
                    name="phone"
                    placeholder="رقم الهاتف *"
                    required
                    />
                </div>
                </div>
                <div className="inputs">
                <div className="input">
                    <textarea
                    name="message"
                    placeholder="أكتب رسالتك هنا *"
                    required
                    ></textarea>
                </div>
                </div>
                <button aria-label="Send Message Form" id="submit-button" type="submit">
                إرسال الان <i className="las la-arrow-right"></i>
                </button>
            </form>
            </div>
        </div>
        </div>
    </div>
    </section>
);
}

export default Contact;
