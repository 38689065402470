import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate } from 'react-router-dom';
import ShowButton from './ShowButton';

function AddUser() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [active, setActive] = useState("");    
    const [img, setImg] = useState(null);    
    const [email, setEmail] = useState(null);    


    
    const clearForm = () => {
        const formInputs = document.querySelectorAll('input[type="text"], input[type="password"],input[type="email"], input[type="file"]');
        formInputs.forEach((input) => {
            input.value = "";
        });
    };
    
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Make sure the file input is a File object
        // Ensure all necessary data is available
        if (!username || !password || !img || !active || !email) {
            console.error('Missing required data');
            return;
        }
    
        // Create a FormData object to send the data including the image file
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('img', img);
        formData.append('active', active);
        formData.append('email', email);

        axios
            .post('/add-user', formData, config)
            .then((response) => {
                // Handle the success response here
                console.log('User added or updated successfully', response.data);
            })
            .catch((error) => {
                // Handle any errors here
                console.error('Error adding or updating user', error);
            });


            clearForm();
            setActive("");
            setPassword("");
            setUsername("");
            setEmail("");

    };
    






    const [show, setShow] = useState(false);

    const handleToggle = () => {
        setShow(!show);
    };

    const getIfLogin = async () => {
        try {
            const res = await axios.get('session-admin');
            if (!res.data.valid) {
                navigate('/admin-login');
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getIfLogin();
    }, []);

    return (
        <div>
            {/* Your loading screen here */}
            <section className='dashboard'>
                <div className='edit-form'>
                    {/* Your success and error alert messages here */}
                    <form onSubmit={handleSubmit}>
                        <div className='input'>
                            <label> الصورة </label>
                            <input required onChange={(event) => setImg(event.target.files[0])} type='file' name="img" accept="image/*"/>

                        </div>

                        <div className='input'>
                            <label> الاسم </label>
                            <input
                                onChange={(event) => setUsername(event.target.value)}
                                type='text'
                                required
                                maxLength="100"
                                name='username'
                            />
                        </div>

                        <div className='input'>
                            <label> كلمة المرور </label>
                            <input
                                onChange={(event) => setPassword(event.target.value)}
                                type={show ? 'text' : 'password'}
                                placeholder="New Password"
                                maxLength="100"
                                name='password'
                                required
                            />
                            <ShowButton className="show-btn" show={show} handleToggle={handleToggle} />
                        </div>


                        <div className='input'>
                            <label> البريد الإلكتروني </label>
                            <input
                                onChange={(event) => setEmail(event.target.value)}
                                type='email'
                                required
                                maxLength="150"
                                name='email'
                            />
                        </div>

                        <div className='input'>
                            <label> الحالة </label>
                            <select
                                required
                                onChange={(e) => {
                                    setActive(e.target.value);
                                }}
                            >
                                <option value="0">غير مفعل</option>
                                <option value="1">مفعل</option>
                            </select>
                        </div>

                        <button type='submit'> حفظ </button>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default AddUser;
