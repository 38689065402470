import './css/login.css';
import { Link, useNavigate } from 'react-router-dom';
import LoginImg from './images/landingback.webp';
import React, { useState,useEffect } from 'react';
import ShowButton from './ShowButton';

import axios from '../config/index';



function Login() {


    const [loginStatus, setLoginStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorlogin, setErrorlogin] = useState("");


    const navigate = useNavigate();    



        
    const [show, setShow] = useState(false);

    const handleToggle = () => {
        setShow(!show);
    };


    const logout = () => {
        axios
        .get('logout')
        .then((res) => {
            if (res.data.logout) {
                window.location.href = '/login';

    
            } else {
                window.location.href = '/login';
            }
        })
        .catch((err) => console.log(err));
    };
    

    

    useEffect(() => {
        axios
        .get('/session-user')
        .then((res) => {
            if (res.data.valid) {
                navigate('/');
            }
        })
        .catch((err) => console.log(err));
    }, []);
    


    

        
    const login = (e) => {
        e.preventDefault();


        setLoading(true);
        axios.post("/login", {
            email: email,
            password: password,
        })
        .then((response) => {
            if (response.data.message) {
                setLoginStatus(response.data.message);
                setLoading(false);
                setErrorlogin("هناك خطأ في اسم المستخدم او كلمة المرور")
            } else {
                setLoading(false);
                setLoginStatus("Successfully logged in.");
                window.location.href = '/';

            }
        })
        .catch((err) => {
            setLoading(false);
            setLoginStatus("Failed to log in. Please try again.");
        });
    };






    
return (
    <>

        <section className='login'>
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <div className='login-content'>
                <h1> سجل دخولك الان للحصول على أفضل ترشيحتنا لك </h1>


                <form>

                    {errorlogin && (
                        <div className='error-msg'>
                            {errorlogin}
                        </div>
                    )}
                    

                <div className='input'>
                    <label>البريد الألكتروني</label>
                    <input
                    type='text'
                    name='email'
                    onChange={(e) => {
                        setEmail(e.target.value);}}
                    required
                    
                    />
                </div>

                <div className='input'>
                    <label> كلمة السر</label>
                    <input
                    type={show ? 'text' : 'password'}
                    name='password'
                    required
                    onChange={(e) => {
                        setPassword(e.target.value);}}
                    />

                <ShowButton className="show-btn" show={show} handleToggle={handleToggle} />

                </div>


                {loading ? (
                    <button disabled> ...جاري تسجيل الدخول </button>
                ) : (
                    <button onClick={login} type='submit'>
                    تسجيل الدخول
                    </button>
                )}


                <Link to='/sign-up'> ليس لديك حساب ؟ </Link>
                </form>
            </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
            <div className='login-content'>
                <img src={LoginImg} alt='login' />
            </div>
            </div>
        </div>
        </div>
    </section>

    </>
);
}

export default Login;
