import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllComments(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllCommentsContent, setAllComments] = useState([]);
    const [Blogs, setBlogs] = useState([]);
    const [Users, setUsers] = useState([]);

    axios.defaults.withCredentials = true;





    const GetAllComments = ()=>{
        axios.post('AllItems', {
            table: 'comments',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllComments(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session-admin')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'comments',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                GetAllComments();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };



        
    const GetAllBlogs = ()=>{
        axios.post('AllItems', {
            table: 'blog',
        })
        .then((res) => {
            if (res.data) {
                setBlogs(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    const GetAllUsers = ()=>{
        axios.post('AllItems', {
            table: 'users',
        })
        .then((res) => {
            if (res.data) {
                setUsers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    
    




    const getBlogName = (blog_id) => {
        const subcategory = Blogs.find((sub) => sub.id === blog_id);
        return subcategory ? subcategory.header : '';
    };

    
    const getUserEmail = (user_id) => {
        const subcategory = Users.find((sub) => sub.id === user_id);
        return subcategory ? subcategory.email : '';
    };



useEffect(() => {

    GetAllComments();
    getIfLogin();
    GetAllBlogs()
    GetAllUsers();
}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th> التعليق </th>
                            <th> بريد صاحب التعليق </th>
                            <th> عنوان المقال </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllCommentsContent.length ? (

AllCommentsContent.map(comment => {

                        const userEmail = getUserEmail(comment.user_id);
                        const BlogHead = getBlogName(comment.blog_id);

                        return (
                            <tr key={comment.id}>
                            <td><h6>{comment.id}</h6></td>

                            <td><h6>{comment.comment}</h6></td>
                            <td><h6>{userEmail}</h6></td>
                            <td><h6>{BlogHead}</h6></td>
                            <td>
                                <button className='delete' onClick={() => deleteItem(comment.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>
                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllComments; 