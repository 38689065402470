import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../config/index';
import './css/blogpage.css';
import BlogContentImg2 from "./images/blogs/blogImage05-1024x768.jpg";

import PageHeader from './PageHeader';
import BlogItemImg from './images/ana/img_2_0.webp';
import UserImg1 from './images/ana/img_3_1.webp';
import UserImg2 from './images/ana/img_1_3.webp';
import { Link as RouterLink } from 'react-router-dom';

function BlogPage() {
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get('blogId');
const [blog, setBlog] = useState({});
const [blogs, setBlogs] = useState([]);
const [subCategories, setSubCategories] = useState([]);
const [searchWords, setSearchWords] = useState([]);
const [AllComments, setAllComments] = useState([]);
const [Allusers, setUsers] = useState([]);


const [user_id, setuser_id] = useState('');
const [blog_id, setblog_id] = useState(id);
const [comment, setComment] = useState('');
const [userLoggedIn, setuserLoggedIn] = useState(false); // State to track changes

    
const clearForm = () => {
    const formInputs = document.querySelectorAll('input[type="text"], input[type="password"],textarea,input[type="email"], input[type="file"]');
    formInputs.forEach((input) => {
        input.value = "";
    });
};
const handleSubmit = (event) => {
    event.preventDefault();
    console.log(user_id);

    // Ensure all necessary data is available
    if (!user_id || !blog_id || !comment) {
    console.error('Missing required data');
    return;
    }

    axios
    .post('/add-comment', { user_id, blog_id, comment })
    .then((response) => {
        // Clear the comment input
        clearForm();
        setComment("");

        // Refresh the page after a successful comment submission
        window.location.reload();
    })
    .catch((error) => {
        // Handle any errors here
    });
};


useEffect(() => {
    axios
.get('/session-user')
.then((res) => {
    if (res.data.valid) {
        setuser_id(res.data.userId);
        setuserLoggedIn(true);
    } 
})
.catch((err) => console.log(err));
},[]);



const getBlogs = () => {


    axios
    .post('AllItems', {
        table: 'blog',
    })
    .then((res) => {
        if (res.data) {
        setBlogs(res.data);
        } else {
        console.log('Error happened');
        }
    })
    .catch((err) => console.log(err));
};

const fetchBlog = (itemId) => {
    const table = 'blog';
    axios
    .post('get-item', { itemId, table })
    .then((res) => {
        if (res.data.message) {
        // Handle the case when no data is found for the given itemId
        } else {
        setBlog(res.data);
        }
    })
    .catch((err) => console.log(err));
};

const getSubCategories = () => {
    axios
    .post('AllItems', {
        table: 'sub_categories',
    })
    .then((res) => {
        if (res.data) {
        setSubCategories(res.data);
        } else {
        console.log('Error happened');
        }
    })
    .catch((err) => console.log(err));
};


var flag = 0;


const updateBlogViews = async () => {
    try {
        flag =1;

        await axios.put('/add-blog-view', { blog_id: id });
        console.log('Blog views updated successfully');
    } catch (error) {
        console.error('Error updating blog views', error);
    }
};


useEffect(() => {
    if(flag == 0){
        updateBlogViews();
    }
}, []);

const getAllUsers = () => {
    axios
    .post('AllItems', {
        table: 'users',
    })
    .then((res) => {
        if (res.data) {
            setUsers(res.data);
        } else {
        console.log('Error happened');
        }
    })
    .catch((err) => console.log(err));
};


const getCategoryName = (category_id) => {
    const subcategory = subCategories.find((sub) => sub.id === category_id);
    return subcategory ? subcategory.name : '';
};


const getUserName = (userId) => {
    const subcategory = Allusers.find((user) => user.id === userId);
    return subcategory ? subcategory.username : '';
};


const getUserImg = (userId) => {
    const subcategory = Allusers.find((user) => user.id === userId);
    return subcategory ? subcategory.img : '';
};


const setSearchArray = (text) => {
    if (text) {
    const delimiter = ','; // You can use a space as a delimiter to split the text into words
    const textArray = text.split(delimiter);
    setSearchWords(textArray);
    } else {
    setSearchWords([]); // Set an empty array if text is undefined or empty
    }
};



const GetAllComments = ()=>{
    axios.post('AllItems', {
        table: 'comments',
    })
    .then((res) => {
        if (res.data) {
            setAllComments(res.data);
        } else {
            console.log("Error happened");
        }
    })
    .catch((err) => console.log(err));
};




useEffect(() => {
    getBlogs(); // Fetch all blogs
    fetchBlog(id); // Fetch the specific blog with the given 'id'
    getSubCategories(); // Fetch all sub-categories
    getAllUsers();
    GetAllComments();
}, [id]);

useEffect(() => {
    setSearchArray(blog.search_words);
}, [blog]);



return (
    <>
    {blog && blogs.length > 0 ? (
        <>
        <PageHeader
            PageHeaderImg={BlogContentImg2}
            category={getCategoryName(blog.category_id)}
            text={blog.header}
        />
        <section className="blog-page">
            <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="latest-blogs">
                    {blogs.slice(-3).map((blogItem, index) => {
                    const subcategoryName = getCategoryName(blogItem.category_id);
                    return (
                        <div className="latest-blog" key={index}>
                        <h1>
                            {subcategoryName} <i className="las la-tag"></i>
                        </h1>
                        <RouterLink to={`/blog-page?blogId=${blogItem.id}`}>
                            {blogItem.header}
                        </RouterLink>
                        </div>
                    );
                    })}
                </div>

                <div className="tag-words">
                    <h1>كلمات بحثية متعلقة بالمقال</h1>
                    {searchWords.map((word, index) => (
                    <span key={index}>
                        {word} <i className="las la-bullseye"></i>
                    </span>
                    ))}
                </div>
                </div>

                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="blog-page-content">
                    <img src={`uploads/${blog.img}`} alt="blog item image" />
                    <h1>{blog.header}</h1>
                    <p><div dangerouslySetInnerHTML={{ __html: blog.para }} /></p>
                    <div className="cat">
                    <h6>القسم: </h6>
                    <span>{getCategoryName(blog.category_id)}</span>
                    </div>


                    {userLoggedIn ? (
                    <div className="put-comment">
                    <form onSubmit={handleSubmit}>
                        <h1>ضع تعليقك على هذا المحتوى</h1>
                        <label>التعليق</label>
                        <textarea name='comment' required onChange={(event) => setComment(event.target.value)} maxLength="600"></textarea>
                        <button  type='submit'>نشر التعليق</button>
                    </form>
                    </div>
                    ):(
                        <>
                        <h6> سجل الدخول في حسابك لوضع تعليق على المقال </h6>

                        </>
                    )}
                    


                        <div className="comments">
                        {AllComments.length > 0 ? (
                            AllComments
                            .filter(comment => comment.blog_id == blog_id)
                            .map((comment, index) => {
                                // Fetch user name and image here, assuming these functions are asynchronous
                                const userName = getUserName(comment.user_id); // Make sure this function returns a promise or handle async logic
                                const userImg = getUserImg(comment.user_id);     // Same as above

                                return (
                                <div className="comment" key={index}>
                                    <div className="info">
                                    <img src={`uploads/${userImg}`} alt="user img" />
                                    <h1>{userName}</h1>
                                    </div>
                                    <p>{comment.comment}</p>
                                </div>
                                );
                            })
                        ) : (
                            <h6> لا يوجد تعليقات </h6>
                        )}
                        </div>
                    
                

                </div>
                </div>
            </div>
            </div>
        </section>
        </>
    ) : (
        <div>Loading...</div>
    )}
    </>
);
}

export default BlogPage;
