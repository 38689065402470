import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditSubCategory() {



    useEffect(() => {
        axios.get('session-admin')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session-admin is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [subCategory, setSubCategory] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        name: "",
        category_name: "",

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (subCategory.id) {
        setFormInputs({
            name: subCategory.name,
            category_name: subCategory.category_name,
        });
        }
    }, [subCategory]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { name, category_name} = formInputs;
        
        
            try {

            const response = await axios.put("/edit-sub-category", {id, name, category_name });
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setSubCategory((prevSubCategory) => ({
                ...prevSubCategory,
                name,
                category_name,
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            }
        };




    axios.defaults.withCredentials = true;
    
    const getSubCategories = (itemId) => {
        let table = "sub_categories";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setSubCategory(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session-admin')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getSubCategories(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getSubCategories(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> اسم القسم  </label>
                                <input value={formInputs.name}  onChange={handleChange} type='text' required="required"  maxLength="250" name='name'/>
                            </div>

                            
                        <div className='input'>
                            <label> المجال </label>
                            <select
                                required
                                onChange={handleChange}
                                name="category_name"
                            >
                                <option selected value={formInputs.category_name}> {formInputs.category_name} </option>

                                <option value="التحليلات"> التحليلات </option>
                                <option value="التعليم"> التعليم </option>
                                <option value="المقالات"> المقالات </option>
                                <option value="استراتجيات التداول"> استراتجيات التداول </option>
                                <option value="افضل شركات التداول"> افضل شركات التداول </option>

                            </select>
                        </div>



                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditSubCategory;
