
import Contact from './Contact';
import PageHeader from './PageHeader';

import ContactImg from './images/contact.webp';

import { Link as RouterLink } from 'react-router-dom';





function ContactPage(){
    return(
        <>

            <PageHeader   PageHeaderImg={ContactImg} category="تواصل معنا" text="نحن نتطلع إلى الاستماع إليك ومساعدتك في أي وقت"/>

            <Contact />



        </>
    )
}

export default ContactPage;