import './css/about.css';

import AboutImg from './images/aboutimg.webp';

function About(){
    return(
        <>
            <section className='about'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='about-img'>
                                <img src={AboutImg} alt="about img" />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h1>  ابدأ في تعلم وكسب المال معنا </h1>
                                <p> تعرف على فريق عمل CMTPFX ورؤيتنا. نحن مكونون من فريق محترف ومتخصص في مجال التداول والاستثمار. رؤيتنا هي توفير الخدمات الأفضل وتمكين عملائنا لتحقيق نجاح مالي مستدام  </p>

                                <ul>
                                    <li>
                                    <i class="las la-check"></i>
                                    تطوير مهارات تداول
                                    </li>
                                    <li>
                                    <i class="las la-check"></i>
                                    معرفة أسواق مالية
                                    </li>
                                    <li>
                                    <i class="las la-check"></i>
                                    تحليل سوق مال
                                    </li>
                                    <li>
                                    <i class="las la-check"></i>
                                    استراتيجيات تداول
                                    </li>
                                    <li>
                                    <i class="las la-check"></i>
                                    تحقيق أرباح مالية
                                    </li>
                                    <li>
                                    <i class="las la-check"></i>
                                    اتخاذ قرارات دقيقة
                                    </li>
                                </ul>

                                <h3> أحصل على </h3>
                                <h4>  5000 + كورسات وكتب تعليمية</h4>

                                <div className='about-benefits'>
                                    <div className='benefit'>
                                    <i class="las la-file-video"></i>
                                        <div>
                                            <h2> تعلم مرئي  </h2>
                                            <p> الفيديوهات التعليمية تقدم لك فرصة لفهم المفاهيم والاستراتيجيات بشكل أفضل من خلال الشرح المرئي</p>
                                        </div>
                                    </div>

                                    <div className='benefit'>
                                    <i class="las la-photo-video"></i>
                                        <div>
                                            <h2> تنوع المواضيع </h2>
                                            <p>  نقدم مجموعة متنوعة من الفيديوهات التعليمية تغطي مختلف جوانب التداول والاستثمار </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default About;