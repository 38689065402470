import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import Benefits from './components/Benefits';
import Features from './components/Features';
import VideosBenefits from './components/VideosBenefits';
import About from './components/About';
import Number from './components/Number';
import Services from './components/Services';
import Blog from './components/Blog';
import Analysis from './components/Analysis';
import Footer from './components/Footer';
import BlogPage from './components/BlogPage';
import AnalysisPage from './components/AnalysisPage';
import EducationPage from './components/EducationPage';
import ArticlesPage from './components/ArticlesPage';
import TradingStratgiesPage from './components/TradingStratgiesPage';
import TradingCompanies from './components/TradingCompanies';
import PageHeader from './components/PageHeader';

//pages 

import PageHeaderImage from './components/images/landingback.webp';
import ContactPage from './components/ContactPage';
import Login from './components/Login';








// Admin Pages


import SideNav from "./components/Admin/SideNav";
import LoginAdmin from "./components/Admin/LoginAdmin";


import SearchBar from "./components/Admin/SearchBar";
import Dashboard from "./components/Admin/Dashboard";
import AddAdmin from './components/Admin/AddAdmin';
import EditAdmin from './components/Admin/EditAdmin';
import AllAdminsPage from './components/Admin/AllAdminsPage';

import AddUser from './components/Admin/AddUser';
import EditUser from './components/Admin/EditUser';
import AllUsers from './components/Admin/AllUsers';

import AddSubCategory from './components/Admin/AddSubCategory';
import EditSubCategory from './components/Admin/EditSubCategory';
import AllSubCategories from './components/Admin/AllSubCategories';


import AllBlogs from './components/Admin/AllBlogs';
import AddBlog from './components/Admin/AddBlog';
import EditBlog from './components/Admin/EditBlog';



import AllEduVideos from './components/Admin/AllEduVideos';
import AddEduVideo from './components/Admin/AddEduVideo';
import EditEduVideo from './components/Admin/EditEduVideo';


import AllEduBooks from './components/Admin/AllEduBooks';
import AddEduBook from './components/Admin/AddEduBook';
import EditEduBook from './components/Admin/EditEduBook';


import AllComments from './components/Admin/AllComments';
import SignUp from './components/SignUp';
import EmailConfirmation from './components/EmailConfirmation';
import Blogs from './components/Blogs';

function App() {


    const [sessionExists, setSessionExists] = useState("");
    const servicesRef = useRef(0);
    const location = useLocation();
    const hideHeaderFooter =
    location.pathname === "/dashboard" ||
    location.pathname === "/all-admins" ||
    location.pathname === "/edit-admin" ||
    location.pathname === "/add-admin" ||
    location.pathname === "/all-users" ||
    location.pathname === "/all-waiting-users" ||
    location.pathname === "/edit-user" ||
    location.pathname === "/add-user"||
    location.pathname === "/all-sub-categories" ||
    location.pathname === "/edit-sub-category" ||
    location.pathname === "/add-sub-category"||
    location.pathname === "/all-blogs"||
    location.pathname === "/add-blog"||
    location.pathname === "/edit-blog"||
    location.pathname === "/all-edu-videos"||
    location.pathname === "/add-edu-video"||
    location.pathname === "/edit-edu-video"||
    location.pathname === "/all-edu-books"||
    location.pathname === "/add-edu-book"||
    location.pathname === "/edit-edu-book"||
    location.pathname === "/all-comments";





return (
    <div className="App">
        <SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        />

            <Navbar className={!hideHeaderFooter ? "" : "hide"} />

            <Routes>
            

            <Route path="/" element={
                    <>
                        <Landing />
                        <Benefits />
                        <Features />
                        <VideosBenefits />
                        <About />
                        <Number />
                        <Services />
                        <Blog />

                        
                    </>
                } />


                
            <Route path="/blog-page" element={
                    <>
                        <BlogPage />

                        
                    </>
                } />


                <Route path="/analysis" element={
                    <>
                        <AnalysisPage />

                        
                    </>
                } />


            <Route path="/blogs" element={
                    <>
                        <Blogs />

                        
                    </>
                } />


            <Route path="/education" element={
                    <>
                        <EducationPage />

                        
                    </>
                } />


            <Route path="/articles" element={
                    <>
                        <ArticlesPage />

                        
                    </>
                } />



            <Route path="/trading-strategy" element={
                    <>
                        <TradingStratgiesPage />

                        
                    </>
                } />


                
            <Route path="/trading-companies" element={
                    <>
                        <TradingCompanies />

                        
                    </>
                } />


                
                    <Route path="/about-us" element={
                        <>
                            <PageHeader  PageHeaderImg={PageHeaderImage} category=" عن الشركة " text="سواء كنت مبتدئًا في عالم التداول أو محترفًا ذو خبرة، فإننا هنا لنقدم لك المعرفة والأدوات اللازمة للنجاح."/>
                            <About />
                            <Number />
                            <Benefits />

                            
                        </>
                    } />


                                    
                    <Route path="/contact-us" element={
                        <>
                            <ContactPage />

                            
                        </>
                    } />

                        
                <Route path="/login" element={
                        <>
                            <Login />

                            
                        </>
                    } />

                                            
                <Route path="/sign-up" element={
                        <>
                            <SignUp />

                            
                        </>
                    } />


<Route path="/email-confirmation" element={
                        <>
                            <EmailConfirmation />

                            
                        </>
                    } />





        
            <Route path="/admin-login"element={<><LoginAdmin sessionExists={sessionExists} setSessionExists={setSessionExists} /></>}/>
            <Route path="/dashboard"element={ <><Dashboard /> </>}/>
            <Route path="/all-admins" element={ <><AllAdminsPage /> </>}/>
            <Route path="/edit-admin" element={<><EditAdmin /></>} />
            <Route path="/add-admin" element={<><AddAdmin /></>}/>

            <Route path="/all-users" element={ <><AllUsers /> </>}/>
            <Route path="/edit-user" element={<><EditUser /></>} />
            <Route path="/add-user" element={<><AddUser /></>}/>

            <Route path="/all-sub-categories" element={ <><AllSubCategories /> </>}/>
            <Route path="/edit-sub-category" element={<><EditSubCategory /></>} />
            <Route path="/add-sub-category" element={<><AddSubCategory /></>}/>


            <Route path="/all-blogs" element={ <><AllBlogs /> </>}/>
            <Route path="/add-blog" element={ <><AddBlog /> </>}/>
            <Route path="/edit-blog" element={ <><EditBlog /> </>}/>

            <Route path="/all-edu-videos" element={ <><AllEduVideos /> </>}/>
            <Route path="/add-edu-video" element={ <><AddEduVideo /> </>}/>
            <Route path="/edit-edu-video" element={ <><EditEduVideo /> </>}/>


            <Route path="/all-edu-books" element={ <><AllEduBooks /> </>}/>
            <Route path="/add-edu-book" element={ <><AddEduBook/> </>}/>
            <Route path="/edit-edu-book" element={ <><EditEduBook /> </>}/>


            <Route path="/all-comments" element={ <><AllComments /> </>}/>

        </Routes>

        <Footer className={!hideHeaderFooter ? "" : "hide"} />


        
    </div>
);
}

export default App;
