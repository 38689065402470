import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate } from 'react-router-dom';

function AddEduVideo() {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [youtube_video_link, setyoutube_video_link] = useState('');

    const clearForm = () => {
        setName('');
        setyoutube_video_link('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Ensure all necessary data is available
        if (!name || !youtube_video_link) {
            console.error('Missing required data');
            return;
        }

        axios
            .post('/add-edu-videos', { name, youtube_video_link })
            .then((response) => {
                // Handle the success response here
                clearForm();
            })
            .catch((error) => {
                // Handle any errors here
            });
    };

    const getIfLogin = async () => {
        try {
            const res = await axios.get('session-admin');
            if (!res.data.valid) {
                navigate('/admin-login');
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getIfLogin();
    }, []);

    return (
        <div>
            <section className='dashboard'>
                <div className='edit-form'>
                    <form onSubmit={handleSubmit}>
                        <div className='input'>
                            <label> العنوان </label>
                            <input
                                type='text'
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                required
                                maxLength="100"
                                name='name'
                            />
                        </div>

                        <div className='input'>
                            <label> رابط الفيديو على اليوتيوب </label>
                            <input
                                type='text'
                                value={youtube_video_link}
                                onChange={(event) => setyoutube_video_link(event.target.value)}
                                required
                                maxLength="250"
                                name='youtube_video_link'
                            />
                        </div>

                        <button type='submit'>حفظ</button>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default AddEduVideo;
