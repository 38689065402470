import './css/sidenav.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import {Link, useNavigate} from 'react-router-dom';
import { useLocation } from "react-router-dom";


import Logo from '../images/logo.png'




function SideNav(props){

    
const navigate = useNavigate();
const [showUserOptions, setShowUserOptions] = useState(false);
const [user, setUser] = useState('');
const location = useLocation();

const [userDataLoaded, setUserDataLoaded] = useState(false); // New state variable




axios.defaults.withCredentials = true;


const logout = () => {
    axios
    .get('/logout-admin')
    .then((res) => {
        console.log("Response status code: " + res.status);
        console.log("Response data: " + JSON.stringify(res.data));

        if (res.data.logout) {
        props.setSessionExists(false);
        console.log("Logout successful");
        navigate('/admin-login');
        } else {
        console.log("Logout not successful");
        navigate('/admin-login');
        }
    })
    .catch((err) => {
        console.log("Error occurred: " + err);
    });

};


const handleUserOptionsClick = () => {
    setShowUserOptions(!showUserOptions);
};

const getUser = (userId) => {
    console.log(userId);
    axios
    .post('admin', { userId })
    .then((res) => {
        if (res.data.message) {
            console.log(res.data.message);

        } else {
            setUser(res.data);
            setUserDataLoaded(true); 

        }
    })
    .catch((err) => console.log(err));

};


const openCloseNav = ()=>{
    let SideNav = document.querySelector(".sidenav");
    let searchBar = document.querySelector(".search-bar");
    let dashboard = document.querySelector(".dashboard");

    if(SideNav.classList.contains("active")){
        SideNav.classList.remove("active");
        searchBar.classList.remove("active");
        dashboard.classList.remove("active");
    }else{
        SideNav.classList.add("active");
        searchBar.classList.add("active");
        dashboard.classList.add("active");
    }
    

}


useEffect(() => {
    axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {
            props.setSessionExists(true);
            getUser(res.data.userId);
            setUserDataLoaded(true); 

            console.log(userDataLoaded);

        } else {
            props.setSessionExists(false);
            getUser(0);
            console.log(userDataLoaded);

        }
    })
    .catch((err) => console.log(err));
}, [props.sessionExists]);













    return(
        <>
            
            <div  className={`${props.className} sidenav`}>
                <button onClick={openCloseNav} className='open-close-nav'> <i class="las la-bars"></i> </button>
                <div className="sidenav-content">
                    <div className="user-info">
                    {userDataLoaded && props.sessionExists ? (
                        <>
                            <img src={Logo} alt="" />
                                <h1>  {user.username} : أهلا وسهلا  </h1>
                        </>
                            ):null}
                    </div>
                


                <div className='sidenav-elements'>
                    <ul>
                    

                        <li className={location.pathname === "/dashboard"? 'active':null}>
                            <Link to="/dashboard">
                            <i class="las la-home"></i>
                                لوحة التحكم
                            </Link>
                        </li>

                        
                        
                        <li className={location.pathname === "/all-admins"? 'active':null}>
                            <Link to="/all-admins">
                                <i class="las la-poll-h"></i>
                                المشرفين
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-users"? 'active':null}>
                            <Link to="/all-users">
                                <i class="las la-poll-h"></i>
                                المستخدمين
                            </Link>
                        </li>

                            
                        <li className={location.pathname === "/all-sub-categories"? 'active':null}>
                            <Link to="/all-sub-categories">
                                <i class="las la-poll-h"></i>
                                الأقسام
                            </Link>
                        </li>

                                                    
                        <li className={location.pathname === "/all-blogs"? 'active':null}>
                            <Link to="/all-blogs">
                                <i class="las la-poll-h"></i>
                                محتوى الأقسام
                            </Link>
                        </li>


                        
                        <li className={location.pathname === "/all-edu-videos"? 'active':null}>
                            <Link to="/all-edu-videos">
                                <i class="las la-poll-h"></i>
                                الفيديوهات التعليمية
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-edu-books"? 'active':null}>
                            <Link to="/all-edu-books">
                                <i class="las la-poll-h"></i>
                                الكتب التعليمية
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-comments"? 'active':null}>
                            <Link to="/all-comments">
                                <i class="las la-poll-h"></i>
                                التعليقات
                            </Link>
                        </li>








                        <li>
                            <button onClick={logout}>
                                <i class="las la-sign-out-alt"></i>
                                تسجيل الخروج
                            </button>
                        </li>

                        <li>
                            <Link to="/">
                                <i class="las la-search"></i>
                                مشاهدة الموقع
                            </Link>
                        </li>


                    </ul>
                </div>
            </div>
            </div>
        
        </>
    )
}

export default SideNav;