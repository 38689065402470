import './css/articlespage.css';
import PageHeader from './PageHeader';
import './css/analysispage.css';
import './css/blog.css';

import BlogMainImg from './images/blogs/Bitcoin-2.png';

import BlogContentImg1 from './images/blogs/blogImage04-1024x768.jpg';
import BlogContentImg2 from './images/blogs/blogImage05-1024x768.jpg';
import BlogContentImg3 from './images/blogs/blogImage06.jpg';
import BlogContentImg4 from './images/ana/img_2_0.webp';
import BlogContentImg5 from './images/ana/img_1_3.webp';
import BlogContentImg6 from './images/ana/img_2_0.webp';
import BlogContentImg7 from './images/ana/img_3_1.webp';
import BlogContentImg8 from './images/ana/img_4_2.webp';




function ArticlesPage(){
    return(
        <>
            <PageHeader className="all"  PageHeaderImg={BlogContentImg2} category="المقالات" text="المقالات"/>

        
<section className='blog'>
        <div className='container-fluid'>
            <div className="row">

                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                <div className='navigator-page'>
                        <button className='active'>   
                            المقالات
                        </button>
                    </div>
                </div>

                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>

                    <div className="row">
                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg1} />
                                    <span> العملات </span>
                                </div>

                                <h1> هل يمكن لشركة أديداس الاستمرار في بيع منتج Yeezy</h1>
                                <h3> 1-5-2023 </h3>
                                <h4> <span> 19 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg2} />
                                    <span> السلع </span>
                                </div>

                                <h1> توقع سعر تيرا الكلاسيكي: ما هو تيرا الكلاسيكي؟ </h1>
                                <h3> 10-5-2023 </h3>
                                <h4> <span> 13 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg3} />
                                    <span> المؤشرات </span>
                                </div>

                                <h1>من هي كارولين إليسون؟ الرئيس التنفيذي لشركة ألاميدا هو جزء من بانكمان. </h1>
                                <h3> 6-5-2023 </h3>
                                <h4> <span> 17 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>
                        

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg4} />
                                    <span> الاسهم </span>
                                </div>

                                <h1>من هي كارولين إليسون؟ الرئيس التنفيذي لشركة ألاميدا هو جزء من بانكمان. </h1>
                                <h3> 6-5-2023 </h3>
                                <h4> <span> 17 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>



                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg5} />
                                    <span> العملات </span>
                                </div>

                                <h1> هل يمكن لشركة أديداس الاستمرار في بيع منتج Yeezy</h1>
                                <h3> 1-5-2023 </h3>
                                <h4> <span> 19 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg6} />
                                    <span> السلع </span>
                                </div>

                                <h1> توقع سعر تيرا الكلاسيكي: ما هو تيرا الكلاسيكي؟ </h1>
                                <h3> 10-5-2023 </h3>
                                <h4> <span> 13 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg7} />
                                    <span> المؤشرات </span>
                                </div>

                                <h1>من هي كارولين إليسون؟ الرئيس التنفيذي لشركة ألاميدا هو جزء من بانكمان. </h1>
                                <h3> 6-5-2023 </h3>
                                <h4> <span> 17 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>
                        

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className="blog-content">
                                <div className="img">
                                    <img src={BlogContentImg8} />
                                    <span> الاسهم </span>
                                </div>

                                <h1>من هي كارولين إليسون؟ الرئيس التنفيذي لشركة ألاميدا هو جزء من بانكمان. </h1>
                                <h3> 6-5-2023 </h3>
                                <h4> <span> 17 </span> <i class="las la-comment-alt"></i></h4>
                            </div>
                        </div>



                    </div>
                </div>

            </div>
        </div>
    </section>
        </>
    )
}
export default ArticlesPage; 