import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllUsers(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllUsers, setAllUsers] = useState([]);

    axios.defaults.withCredentials = true;





    const GetAllUsers = ()=>{
        axios.post('AllItems', {
            table: 'users',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllUsers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session-admin')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'users',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                GetAllUsers();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };



    const activateItem = (id) => {
        axios
        .put(`/activate-item/${id}`, {
            table: 'users',
        })
        .then((response) => {
            if (response.data.message === 'Item Activating successfully') {
            GetAllUsers();
            } else {
            console.error('Failed to activate result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error activating result:', error);
        });
    };



useEffect(() => {

    GetAllUsers();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-user'> + أضف </Link>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>الصورة</th>
                            <th> الاسم </th>
                            <th> البريد الإلكتروني </th>
                            <th> الحالة </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllUsers.length ? (

                        AllUsers.map(user => {

                        return (
                            <tr key={user.id}>
                            <td><h6>{user.id}</h6></td>

                            <td><img src={`/uploads/${user.img}`} alt="User avatar"/></td>
                            <td><h6>{user.username}</h6></td>
                            <td><h6>{user.email}</h6></td>

                            <td><h5 className={user.active == 0 ? "in-active" : "active"}>{user.active == 0 ? "غير مفعل" : "مفعل"}</h5></td>

                            <td>
                                <Link className='edit' to={`/edit-user?id=${user.id}`}> <i class="las la-edit"></i> </Link>
                            </td>
                            <td>
                                <button className='delete' onClick={() => deleteItem(user.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>

                            {user.status === 0?(<><td><button className='activate' onClick={() => activateItem(user.id)}> <i class="las la-thumbs-up"></i> </button></td></>):null}


                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllUsers;